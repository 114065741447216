
<script>
import DatePicker from 'vue2-datepicker';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	props: ['client'],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
		DatePicker
	},
	data() {
		return {
			name: 'sumary_bank_relationship',
			form: {
				riesgoOtraFilialBBVA: '',
				comentariosRiesgoOtraFilialBBVA: '',
				otrosProductosNoDeuda: '',
				comentariosOtrosProductosNoDeuda: '',
				rarRentabilidad: '',
				comentariosSobreRAR: '',
				otrasCaracteristicasDeLaEstructuraDelRiesgoDispuestoActual: '',
				numeroDeBancosConLosQueElClienteTrabajaUsualmente: '',
				comentariosSobreOtrasCaracteristicasDeLaExposicionDelCliente: '',
				niveldeWL: '',
				analisisDeCompensacionesReciprocidadYTransaccionalidadDelCliente: ''

			},
			formPosicionRiesgo: {
				producto: '',
				limiteActual: '',
				comprometido: '',
				noComprometido: '',
				dispuesto: '',
				comentariosGarantias: '',
				
			},
			posicionesRiesgo: [],
			buro: [],
			bancos: [],
			formBuro: [],
			formBancos: [],

		}
	},
	watch: {
		'client': {
			handler(nVal) {
				this.fillForm()
			},
			deep: true,
			immediate: true
		},
	},
	methods: {
		getClients() {
			return this.client.children
		},
		
		sumTotalRiskPosition(client, key) {
			if(!client.arce.hasOwnProperty('sumary_bank_relationship')) {
				return 0
			}

			let sum = 0

			client.arce.sumary_bank_relationship.posicionesRiesgo.forEach(p => {
				sum += Number(p[key])
			})

			return sum
		},
		savePosition() {
			if(!this.formPosicionRiesgo.id) {
				let id = this.posicionesRiesgo.length ? this.posicionesRiesgo[this.posicionesRiesgo.length - 1].id + 1 : 1
				this.posicionesRiesgo.push(Object.assign({...this.formPosicionRiesgo}, {id: id}))
				this.clearPosition()
				return
			}

			let position = this.posicionesRiesgo.find(p => p.id == this.formPosicionRiesgo.id)

			position.producto = this.formPosicionRiesgo.producto
			position.limiteActual = this.formPosicionRiesgo.limiteActual
			position.comprometido = this.formPosicionRiesgo.comprometido
			position.noComprometido = this.formPosicionRiesgo.noComprometido
			position.dispuesto = this.formPosicionRiesgo.dispuesto
			position.comentariosGarantias = this.formPosicionRiesgo.comentariosGarantias

			this.clearPosition()

			
		},

		clearPosition() {
			this.formPosicionRiesgo.id = 0
			this.formPosicionRiesgo.producto = ''
			this.formPosicionRiesgo.limiteActual = ''
			this.formPosicionRiesgo.comprometido = ''
			this.formPosicionRiesgo.noComprometido = ''
			this.formPosicionRiesgo.dispuesto = ''
			this.formPosicionRiesgo.dispuesto = ''
			this.formPosicionRiesgo.comentariosGarantias = ''
			this.$notify({
				type: 'success',
				group: 'simulator',
				title: 'Success!',
				text: 'El registro se actualizó satisfactoriamente'
			})
			$('#sumaryPositionRiskGroup').modal('hide')

		},
		calcFill() {
			let fields = Object.keys(this.form)

			if(this.form.riesgoOtraFilialBBVA == 'NO') {
				this.form.comentariosRiesgoOtraFilialBBVA = ''
				let index = fields.indexOf('comentariosRiesgoOtraFilialBBVA')
				fields.splice(index, 1)
			}
			if(this.form.otrosProductosNoDeuda == 'NO') {
				this.form.comentariosOtrosProductosNoDeuda = ''
				let index = fields.indexOf('comentariosOtrosProductosNoDeuda')
				fields.splice(index, 1)
			}

			let points = 100 / fields.length
			let fill = 0
			fields.forEach(f => {
				if(this.form[f]) {
					fill += points
				}
			})

			return Math.round(fill)
		},
		
		fillForm() {
			let data = {}
			if(this.client.arce.hasOwnProperty(this.name)) {
				data = {...this.client.arce[this.name]}
			}

			if(!Object.keys(data).length) {
				this.$set(this, 'form', {
					riesgoOtraFilialBBVA: '',
					comentariosRiesgoOtraFilialBBVA: '',
					otrosProductosNoDeuda: '',
					comentariosOtrosProductosNoDeuda: '',
					rarRentabilidad: '',
					comentariosSobreRAR: '',
				})
				this.$set(this, 'bancos', [])
				this.$set(this, 'buro', [])
				return 
			}

			this.$set(this, 'bancos', [...data.bancos] )
			delete data['bancos']
			this.$set(this, 'buro', [...data.buro])
			delete data['buro']

			this.$set(this, 'form', {...data})
		},
		save() {
			let vm = this
			let group = {...this.client}
			group.arceFill[this.name] = this.calcFill()
			group.arce[this.name] =  Object.assign({...this.form}, {buro: [...this.buro], bancos: [...this.bancos]})
			vm._updateArce(group)
		},

	
		sumTotal(item, key) {
			if(!item.length) {
				return 0
			}
			let sum = item.reduce((a, c) => {
				let obj = {}
				obj[key] = Number(a[key]) + Number(c[key])
				return obj
			})

			return sum[key]
		},
		showEditPosition(position) {
			this.formPosicionRiesgo.id = position.id
			this.formPosicionRiesgo.producto = position.producto
			this.formPosicionRiesgo.limiteActual = position.limiteActual
			this.formPosicionRiesgo.comprometido = position.comprometido
			this.formPosicionRiesgo.noComprometido = position.noComprometido
			this.formPosicionRiesgo.dispuesto = position.dispuesto
			this.formPosicionRiesgo.comentariosGarantias = position.comentariosGarantias
			this.showModal('#sumaryPositionRiskGroup')
		},
		showBuroFrom() {
			let buro = this.buro.map(b => { return {...b}})
			this.$set(this, 'formBuro', [...buro])
			this.showModal('#buroModalGroup')
		},
		showBancosForm() {
			let bancos = this.bancos.map(b => { return {...b}})
			this.$set(this, 'formBancos', [...bancos])
			this.showModal('#bancosModalGroup')
		},
		addNewBanco() {
			this.formBancos.push({
				nombre: '',
				tipo_credito: '',
				importe_original_del_financiamiento: '',
				saldo_actual: '',
				procion_circulante_saldo_actual: '',
				largo_plazo_saldo_actual: '',
				destino: '',
				plazo_meses: '',
				vencimiento: '',
				garantias: '',
				aforo: '',
				tasa_de_interes: '',
			})
		},
		addNewBuro() {
			this.formBuro.push({
				nombre: '',
				fecha: '',
				clavePrevencion: '',
				numeroBancos: '',
				mn: '',
				usd: '',
				udis: '',
				h30: '',
				h90: '',
				m90: '',
			})
		},
		deleteItem(index, collection) {
			collection.splice(index, 1)
		},
		saveBuro() {
			this.$set(this, 'buro', [...this.formBuro])
			this.$notify({
				type: 'success',
				group: 'simulator',
				title: 'Success!',
				text: 'El registro se actualizó satisfactoriamente'
			})
			this.cancelBuro()
		},
		saveBancos() {
			this.$set(this, 'bancos', [...this.formBancos])
			this.$notify({
				type: 'success',
				group: 'simulator',
				title: 'Success!',
				text: 'El registro se actualizó satisfactoriamente'
			})
			this.save()
			this.cancelBancos()
		},
		cancelBuro() {
			this.$set(this, 'formBuro', [])
			$('#buroModalGroup').modal('hide') 
			$('#buroSaveModalGroup').modal('hide') 
		},
		cancelBancos() {
			this.$set(this, 'formBancos', [])
			$('#bancosModalGroup').modal('hide') 
			$('#bancosSaveModalGroup').modal('hide') 
		},
		showModal(id) {
			if(document.querySelectorAll(`${id}`).length > 1) {
				$(`body > ${id}`).remove()
				this.showModal(id)
			} else {
				$(id).appendTo("body").modal('show')

			}
		},
		closeModal() {
			this.hiddenSteps()
			this.fillForm()
		},
		...mapMutations({
			_setDataChild: 'arceDetails/setDataChild'
		}),
		...mapActions({
			_updateArce: 'arceDetails/updateArce'
		})
	},
	mounted() {
		$('#formSumaryBankRelationshipGroup').on('hide.bs.modal', this.closeModal)
	},

	beforeDestroy() {
		$('#formSumaryBankRelationshipGroup').off('hide.bs.modal', this.closeModal)
	}
}
</script>
<template>
	<div style="">
		<div class="text-right py-2">
			<button class="s-btn s-btn--primary" @click="showModal('#formSumaryBankRelationshipGroup')">
				Editar
			</button>
		</div>
		<div>
			<div class="">
				<div class="s-collapse__header" data-toggle="collapse" data-target="#c_sbrg_1" aria-expanded="true" aria-controls="c_sbrg_1">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Resumen de la posición de riesgo</span></h6>
					</div>
				</div>
				<div id="c_sbrg_1" class="collapse show" aria-labelledby="headingOne">
					<div class="py-3">
						<table class="s-table w-100">
							<thead>
								<tr>
									<th>Filial</th>
									<th>Limite actual</th>
									<th>Comprometido</th>
									<th>No Comprometido</th>
									<th>Dispuesto</th>
									<th>Comentarios/Gratías</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(c, index) in getClients()" :key="`pr_key_${index}`">
									<td>
										{{c.name}}
									</td>
									<td class="text-right">
										{{ sumTotalRiskPosition(c, 'limiteActual') }}
									</td>
									<td class="text-right">
										{{ sumTotalRiskPosition(c, 'comprometido') }}
									</td>
									<td class="text-right">
										{{ sumTotalRiskPosition(c, 'noComprometido') }}
									</td>
									<td class="text-right">
										{{ sumTotalRiskPosition(c, 'dispuesto') }}
									</td>
									<td>
									</td>
									<td class="text-center">
										<div class="dropdown mx-auto">
											<button class="s-btn s-btn--outline p-1 dropdown-toggle" style="min-height: auto; height: 24px" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												
											</button>
											<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
												<a class="dropdown-item px-2 small" @click="showModal('#sumaryPositionRiskGroup')">Insertar</a>
												<a class="dropdown-item px-2 small" @click="showEditPosition(c)">Editar</a>
												<a class="dropdown-item px-2 small">Eliminar</a>
											</div>
										</div>	
									</td>
								</tr>
								<tr>
									<td class="text-center">
										TOTAL
									</td>
									<td class="text-right">{{sumTotal(posicionesRiesgo, 'limiteActual') | numeral('0,0')}}</td>
									<td class="text-right">{{sumTotal(posicionesRiesgo, 'comprometido') | numeral('0,0')}}</td>
									<td class="text-right">{{sumTotal(posicionesRiesgo, 'noComprometido') | numeral('0,0')}}</td>
									<td class="text-right">{{sumTotal(posicionesRiesgo, 'dispuesto') | numeral('0,0')}}</td>
									<td class="text-right"></td>
									<td class="text-center">
										<div class="dropdown">
											<button class="s-btn s-btn--outline p-1 dropdown-toggle" style="min-height: auto; height: 24px" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												
											</button>
											
										</div>	
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="d-flex flex-wrap mx-n2">
						<div class="col-3 p-2">
							<label class="d-block">
								<p class="m-0"><span class="text-danger">*</span> Riesgo con otra filial de BBVA</p>
								<input class="form-control form-control--disabled" disabled v-model="form.riesgoOtraFilialBBVA">
								
							</label>
						</div>
						<div class="col-4 ml-auto" v-if="form.riesgoOtraFilialBBVA == 'SI'">
							<label class="d-block m-0">
								<p class="m-0"><span class="text-danger">*</span> Comentarios acerca de Riegos con otra filial de BBVA</p>
								<textarea class="form-control form-control--disabled" disabled type="text" v-model="form.comentariosRiesgoOtraFilialBBVA">
								</textarea>
							</label>
						</div>
					</div>
					
				</div>
				<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c1_sbrg_1" aria-expanded="true" aria-controls="c1_sbrg_1">
					<div class="d-flex">
						<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Pool de Bancos</span></h6>
					</div>
				</div>
				<div id="c1_sbrg_1" class="collapse" aria-labelledby="headingOne">
					<div class="py-3">
						<div class="s-collapse__header  mt-2 bg-white" data-toggle="collapse" data-target="#c1_sbrg_1_1" aria-expanded="true" aria-controls="collapseTwo">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>  SISTEMA DE DEUDA FINANCIERA</span></h6>
							</div>
						</div>
						<div id="c1_sbrg_1_1" class="collapse show" aria-labelledby="headingOne">
							<div class="py-2">
								<h6>Buró</h6>
								<table class="s-table w-100">
									<thead>
										<tr>
											<th class="py-1">NOMBRE DEL CLIENTE</th>
											<th class="py-1">FECHA</th>
											<th class="py-1">CLAVE DE PREVENCION</th>
											<th class="py-1">NÚMERO DE BANCOS</th>
											<th class="py-1">M.N</th>
											<th class="py-1">USD VAL.</th>
											<th class="py-1">UDIS VAL.</th>
											<th class="py-1">TOTAL</th>
											<th class="py-1">HASTA 30 D</th>
											<th class="py-1">HASTA 90 D</th>
											<th class="py-1">MÁS DE 90 D</th>
										</tr>
									</thead>
									<tbody>
										<template v-for="(c, index) in getClients()" >
											<template v-if="c.arce.hasOwnProperty('sumary_bank_relationship')">
												<tr v-for="(rb, index2) in c.arce.sumary_bank_relationship.buro" :key="`buror_key_${index}_${index2}`">
													<td>{{rb.nombre}}</td>
													<td>{{rb.fecha}}</td>
													<td>{{rb.clavePrevencion}}</td>
													<td>{{rb.numeroBancos}}</td>
													<td>${{rb.mn | numeral('0,0')}}</td>
													<td>{{rb.usd | numeral('0,0')}}</td>
													<td>{{rb.udis | numeral('0,0')}}</td>
													<td>${{rb.mn | numeral('0,0')}}</td>
													<td>{{rb.h30}}</td>
													<td>{{rb.h90}}</td>
													<td>{{rb.m90}}</td>
												</tr>
											</template>
										</template>
									</tbody>
								</table>
							</div>
							<div v-if="!buro.length">
								<h3 class="text-center m-0">
									Otros Burós del Grupo
								</h3>
								<p class="text-right">No se encontraron registros</p>
							</div>
							<div v-else class="py-4">
								<h6>Otros Burós del Grupo</h6>
								<table class="s-table w-100">
									<thead>
										<tr>
											<th class="py-1">NOMBRE DEL CLIENTE</th>
											<th class="py-1">FECHA</th>
											<th class="py-1">CLAVE DE PREVENCION</th>
											<th class="py-1">NÚMERO DE BANCOS</th>
											<th class="py-1">M.N</th>
											<th class="py-1">USD VAL.</th>
											<th class="py-1">UDIS VAL.</th>
											<th class="py-1">TOTAL</th>
											<th class="py-1">HASTA 30 D</th>
											<th class="py-1">HASTA 90 D</th>
											<th class="py-1">MÁS DE 90 D</th>
										</tr>
									</thead>
									<tbody>
										<tr style="white-space: nowrap;" v-for="(rb, index) in buro" :key="`buror_key_${index}`">
											<td>{{rb.nombre}}</td>
											<td>{{rb.fecha}}</td>
											<td>{{rb.clavePrevencion}}</td>
											<td>{{rb.numeroBancos}}</td>
											<td>${{rb.mn | numeral('0,0')}}</td>
											<td>{{rb.usd | numeral('0,0')}}</td>
											<td>{{rb.udis | numeral('0,0')}}</td>
											<td>${{rb.mn | numeral('0,0')}}</td>
											<td>{{rb.h30}}</td>
											<td>{{rb.h90}}</td>
											<td>{{rb.m90}}</td>
										</tr>
									</tbody>
								</table>
							</div>
								
							
						</div>
					</div>
					<div class="s-collapse__header collapsed  mt-2  bg-white" data-toggle="collapse" data-target="#c1_pdbd" aria-expanded="true" aria-controls="c1_pdbd">
						<div class="d-flex">
							<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> POOL DE BANCOS DETALLADO</span></h6>
						</div>
					</div>
					<div id="c1_pdbd" class="collapse" aria-labelledby="headingOne">
						<div class="py-3 d-flex flex-wrap mx-n2">
							
							<div class="col-12 p-2">
								<div v-if="!bancos.length">
									<p class="text-right">No se encontraron registros</p>
								</div>
								<div v-else class="py-4">
										<table class="w-100 s-table">
												<thead>
													<tr>
														<th class="py-1 text-center">NOMBRE INSTITUCIÓN BANCARIA</th>
														<th class="py-1 text-center">TIPO DE CRÉDITO</th>
														<th class="py-1 text-center">IMPORTE ORIGINAL DEL FINANCIAMIENTO</th>
														<th class="py-1 text-center">SALDO ACTUAL</th>
														<th class="py-1 text-center">PORCIÓN CIRCULANTE SALDO ACTUAL</th>
														<th class="py-1 text-center">LARGO PLAZO SALDO ACTUAL</th>
														<th class="py-1 text-center">DESTINO</th>
														<th class="py-1 text-center">PLAZO MESES</th>
														<th class="py-1 text-center">VENCIMIENTO</th>
														<th class="py-1 text-center">GARANTIAS</th>
														<th class="py-1 text-center">AFORO</th>
														<th class="py-1 text-center">TASA DE INTERES</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(rb, index) in bancos" :key="`rbt_key-${index}`">
														<td class="text-center py-1">{{rb.nombre}}</td>
														<td class="text-center py-1">{{rb.tipo_credito}}</td>
														<td class="text-center py-1">${{rb.importe_original_del_financiamiento}}</td>
														<td class="text-center py-1">${{rb.saldo_actual}}</td>
														<td class="text-center py-1">${{rb.procion_circulante_saldo_actual}}</td>
														<td class="text-center py-1">${{rb.largo_plazo_saldo_actual}}</td>
														<td class="text-center py-1">{{rb.destino}}</td>
														<td class="text-center py-1">{{rb.plazo_meses}}</td>
														<td class="text-center py-1">{{rb.vencimiento}}</td>
														<td class="text-center py-1">{{rb.garantias}}</td>
														<td class="text-center py-1">{{rb.aforo}}%</td>
														<td class="text-center py-1">{{rb.tasa_de_interes}}%</td>
													</tr>
													<tr>
														<td class="text-center py-1">TOTAL</td>
														<td class="text-center py-1"></td>
														<td class="text-center py-1">${{sumTotal(bancos, 'importe_original_del_financiamiento')}}</td>
														<td class="text-center py-1">${{sumTotal(bancos, 'saldo_actual')}}</td>
														<td class="text-center py-1">${{sumTotal(bancos, 'procion_circulante_saldo_actual')}}</td>
														<td class="text-center py-1">${{sumTotal(bancos, 'largo_plazo_saldo_actual')}}</td>
													</tr>
												</tbody>
											</table>
								</div>
								<div>
								</div>
							</div>
						
							<div class="col-12"></div>
							<div class="col-4 p-2">
								<label class="d-block">
									<p class="m-0"><span class="text-danger">*</span>Otras características de la estrucura del riesgo dispuesto actual</p>
									<input class="form-control form-control--disabled" disabled  type="text" v-model="form.otrasCaracteristicasDeLaEstructuraDelRiesgoDispuestoActual">
								</label>
								<div class="py-2" v-if="form.otrosProductosNoDeuda == 'SI'">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Comentarios sobre Otros Productos No Deuda</p>
										<textarea class="form-control form-control--disabled" disabled   type="text" v-model="form.comentariosOtrosProductosNoDeuda">
										</textarea>
									</label>
								</div>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span> Número de bancos con los que el cliente trabaja usualmente</p>
									<input class="form-control form-control--disabled" disabled   type="text" v-model="form.numeroDeBancosConLosQueElClienteTrabajaUsualmente">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span> Comentario sobre otras características de la exposición de cliente</p>
									<input class="form-control form-control--disabled" disabled   type="text" v-model="form.comentariosSobreOtrasCaracteristicasDeLaExposicionDelCliente">
								</label>
							</div>
							<div class="col-12 p-0">
								<!-- <date-picker class="form-control" valueType="format"></date-picker> -->
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span> RAR - Rentabilidad</p>
									<input class="form-control form-control--disabled" disabled   type="text" v-model="form.rarRentabilidad">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0">Nivel de WL</p>
									<input class="form-control form-control--disabled" disabled   type="text" v-model="form.niveldeWL">
								</label>
							</div>
							<div class="col-4 p-2">
								
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span> Comentarios sobre el RAR</p>
									<input class="form-control form-control--disabled" disabled   type="text" v-model="form.comentariosSobreRAR">
								</label>
							</div>
							<div class="col-4 p-2">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span> Análisis de compensaciones, reciprocidad y transaccionalidad del cliente (rentablidad)</p>
									<input class="form-control form-control--disabled" disabled   type="text" v-model="form.analisisDeCompensacionesReciprocidadYTransaccionalidadDelCliente">
								</label>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
		
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<!-- ============================================ -->
		<div class="modal s-modal fade" style="font-size: 0.8em" id="formSumaryBankRelationshipGroup" tabindex="-1" role="dialog" aria-labelledby="formSumaryBankRelationshipGroupLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl " role="document">
				<div class="modal-content">
				<div class="modal-header p-0">
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="">
						<div class="s-collapse__header" data-toggle="collapse" data-target="#c_sbrg_m_1" aria-expanded="true" aria-controls="c_sbrg_m_1">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Resumen de la posición de riesgo</span></h6>
							</div>
						</div>
						<div id="c_sbrg_m_1" class="collapse show" aria-labelledby="headingOne">
							<div class="py-3">
								<table class="s-table w-100">
									<thead>
										<tr>
											<th>Filial</th>
											<th>Limite actual</th>
											<th>Comprometido</th>
											<th>No Comprometido</th>
											<th>Dispuesto</th>
											<th>Comentarios/Gratías</th>
											<th></th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(c, index) in getClients()" :key="`pr_key_${index}`">
											<td>
												{{c.name}}
											</td>
											<td class="text-right">
												{{ sumTotalRiskPosition(c, 'limiteActual') }}
											</td>
											<td class="text-right">
												{{ sumTotalRiskPosition(c, 'comprometido') }}
											</td>
											<td class="text-right">
												{{ sumTotalRiskPosition(c, 'noComprometido') }}
											</td>
											<td class="text-right">
												{{ sumTotalRiskPosition(c, 'dispuesto') }}
											</td>
											<td>
											</td>
											<td class="text-center">
												<div class="dropdown mx-auto">
													<button class="s-btn s-btn--outline p-1 dropdown-toggle" style="min-height: auto; height: 24px" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														
													</button>
													<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
														<a class="dropdown-item px-2 small" @click="showModal('#sumaryPositionRiskGroup')">Insertar</a>
														<a class="dropdown-item px-2 small" @click="showEditPosition(c)">Editar</a>
														<a class="dropdown-item px-2 small">Eliminar</a>
													</div>
												</div>	
											</td>
										</tr>
										<tr>
											<td class="text-center">
												TOTAL
											</td>
											<td class="text-right">{{sumTotal(posicionesRiesgo, 'limiteActual') | numeral('0,0')}}</td>
											<td class="text-right">{{sumTotal(posicionesRiesgo, 'comprometido') | numeral('0,0')}}</td>
											<td class="text-right">{{sumTotal(posicionesRiesgo, 'noComprometido') | numeral('0,0')}}</td>
											<td class="text-right">{{sumTotal(posicionesRiesgo, 'dispuesto') | numeral('0,0')}}</td>
											<td class="text-right"></td>
											<td class="text-center">
												<div class="dropdown">
													<button class="s-btn s-btn--outline p-1 dropdown-toggle" style="min-height: auto; height: 24px" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
														
													</button>
													<div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
														<a class="dropdown-item px-2 small" @click="showModal('#sumaryPositionRiskGroup')">Insertar</a>
														<a class="dropdown-item px-2 small">Editar</a>
														<a class="dropdown-item px-2 small">Eliminar</a>
													</div>
												</div>	
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="d-flex flex-wrap mx-n2">
								<div class="col-3 p-2">
									<label class="d-block">
										<p class="m-0"><span class="text-danger">*</span> Riesgo con otra filial de BBVA</p>
										<div class="s-tf">
											<label class="s-tf__item">
												<input type="radio" name="sbrg_rofB" value="SI" v-model="form.riesgoOtraFilialBBVA">
												<span class="s-tf__btn">SI</span>
											</label>
											<label class="s-tf__item" >
												<input type="radio" name="sbrg_rofB" value="NO" v-model="form.riesgoOtraFilialBBVA">
												<span class="s-tf__btn">NO</span>
											</label>
										</div>
									</label>
								</div>
								<div class="col-4 ml-auto" v-if="form.riesgoOtraFilialBBVA == 'SI'">
									<label class="d-block m-0">
										<p class="m-0"><span class="text-danger">*</span> Comentarios acerca de Riegos con otra filial de BBVA</p>
										<textarea class="form-control" type="text" v-model="form.comentariosRiesgoOtraFilialBBVA">
										</textarea>
										<characters-available :max="500" :text="form.comentariosRiesgoOtraFilialBBVA"></characters-available>

									</label>
								</div>
							</div>
							
						</div>
						<div class="s-collapse__header collapsed  mt-2" data-toggle="collapse" data-target="#c1_sbrg_m_1" aria-expanded="true" aria-controls="c1_sbrg_m_1">
							<div class="d-flex">
								<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> Pool de Bancos</span></h6>
							</div>
						</div>
						<div id="c1_sbrg_m_1" class="collapse" aria-labelledby="headingOne">
							<div class="py-3">
								<div class="s-collapse__header  mt-2 bg-white" data-toggle="collapse" data-target="#c1_sbrg_m_1_1" aria-expanded="true" aria-controls="collapseTwo">
									<div class="d-flex">
										<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span>  SISTEMA DE DEUDA FINANCIERA</span></h6>
									</div>
								</div>
								<div id="c1_sbrg_m_1_1" class="collapse show" aria-labelledby="headingOne">
									<div class="py-2">
										<h6>Buró</h6>
										<table class="s-table w-100">
											<thead>
												<tr>
													<th class="py-1">NOMBRE DEL CLIENTE</th>
													<th class="py-1">FECHA</th>
													<th class="py-1">CLAVE DE PREVENCION</th>
													<th class="py-1">NÚMERO DE BANCOS</th>
													<th class="py-1">M.N</th>
													<th class="py-1">USD VAL.</th>
													<th class="py-1">UDIS VAL.</th>
													<th class="py-1">TOTAL</th>
													<th class="py-1">HASTA 30 D</th>
													<th class="py-1">HASTA 90 D</th>
													<th class="py-1">MÁS DE 90 D</th>
												</tr>
											</thead>
											<tbody>
												<template v-for="(c, index) in getClients()" >
													<template v-if="c.arce.hasOwnProperty('sumary_bank_relationship')">
														<tr v-for="(rb, index2) in c.arce.sumary_bank_relationship.buro" :key="`buror_key_${index}_${index2}`">
															<td>{{rb.nombre}}</td>
															<td>{{rb.fecha}}</td>
															<td>{{rb.clavePrevencion}}</td>
															<td>{{rb.numeroBancos}}</td>
															<td>${{rb.mn | numeral('0,0')}}</td>
															<td>{{rb.usd | numeral('0,0')}}</td>
															<td>{{rb.udis | numeral('0,0')}}</td>
															<td>${{rb.mn | numeral('0,0')}}</td>
															<td>{{rb.h30}}</td>
															<td>{{rb.h90}}</td>
															<td>{{rb.m90}}</td>
														</tr>
													</template>
												</template>
											</tbody>
										</table>
									</div>
									<div v-if="!buro.length">
										<h3 class="text-center m-0">
											Otros Burós del Grupo
										</h3>
										<p class="text-right">No se encontraron registros</p>
									</div>
									<div v-else class="py-4">
										<h6>Otros Burós del Grupo</h6>
										<table class="s-table w-100">
											<thead>
												<tr>
													<th class="py-1">NOMBRE DEL CLIENTE</th>
													<th class="py-1">FECHA</th>
													<th class="py-1">CLAVE DE PREVENCION</th>
													<th class="py-1">NÚMERO DE BANCOS</th>
													<th class="py-1">M.N</th>
													<th class="py-1">USD VAL.</th>
													<th class="py-1">UDIS VAL.</th>
													<th class="py-1">TOTAL</th>
													<th class="py-1">HASTA 30 D</th>
													<th class="py-1">HASTA 90 D</th>
													<th class="py-1">MÁS DE 90 D</th>
												</tr>
											</thead>
											<tbody>
												<tr style="white-space: nowrap;" v-for="(rb, index) in buro" :key="`buror_key_${index}`">
													<td>{{rb.nombre}}</td>
													<td>{{rb.fecha}}</td>
													<td>{{rb.clavePrevencion}}</td>
													<td>{{rb.numeroBancos}}</td>
													<td>${{rb.mn | numeral('0,0')}}</td>
													<td>{{rb.usd | numeral('0,0')}}</td>
													<td>{{rb.udis | numeral('0,0')}}</td>
													<td>${{rb.mn | numeral('0,0')}}</td>
													<td>{{rb.h30}}</td>
													<td>{{rb.h90}}</td>
													<td>{{rb.m90}}</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div>
										<button class="s-btn s-btn--primary s-btn--outline" @click="showBuroFrom">Administrar Buró</button>
									</div>	
									
								</div>
							</div>
							<div class="s-collapse__header collapsed  mt-2  bg-white" data-toggle="collapse" data-target="#c1_m_pdbd" aria-expanded="true" aria-controls="c1_m_pdbd">
								<div class="d-flex">
									<h6 class="title font-weight-normal"><svg-icon name="chevrondown" /> <span> POOL DE BANCOS DETALLADO</span></h6>
								</div>
							</div>
							<div id="c1_m_pdbd" class="collapse" aria-labelledby="headingOne">
								<p class="p-2">El cuadro de pasivos se deberá llenar en Moneda Nacional, si tienes créditos en dólares, conviértelos al tipo de cambio vigente y pon la nota en el cuadro de comentarios</p>
								<div class="py-3 d-flex flex-wrap mx-n2">
									
									<div class="col-12 p-2">
										<div v-if="!bancos.length">
											<p class="text-right">No se encontraron registros</p>
										</div>
										<div v-else class="py-4">
											<h2>Cuadro de Pasivos en M.N</h2>
											<table class="w-100 s-table">
												<thead>
													<tr>
														<th class="py-1 text-center">NOMBRE INSTITUCIÓN BANCARIA</th>
														<th class="py-1 text-center">TIPO DE CRÉDITO</th>
														<th class="py-1 text-center">IMPORTE ORIGINAL DEL FINANCIAMIENTO</th>
														<th class="py-1 text-center">SALDO ACTUAL</th>
														<th class="py-1 text-center">PORCIÓN CIRCULANTE SALDO ACTUAL</th>
														<th class="py-1 text-center">LARGO PLAZO SALDO ACTUAL</th>
														<th class="py-1 text-center">DESTINO</th>
														<th class="py-1 text-center">PLAZO MESES</th>
														<th class="py-1 text-center">VENCIMIENTO</th>
														<th class="py-1 text-center">GARANTIAS</th>
														<th class="py-1 text-center">AFORO</th>
														<th class="py-1 text-center">TASA DE INTERES</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(rb, index) in bancos" :key="`rbt_key-${index}`">
														<td class="text-center py-1">{{rb.nombre}}</td>
														<td class="text-center py-1">{{rb.tipo_credito}}</td>
														<td class="text-center py-1">${{rb.importe_original_del_financiamiento}}</td>
														<td class="text-center py-1">${{rb.saldo_actual}}</td>
														<td class="text-center py-1">${{rb.procion_circulante_saldo_actual}}</td>
														<td class="text-center py-1">${{rb.largo_plazo_saldo_actual}}</td>
														<td class="text-center py-1">{{rb.destino}}</td>
														<td class="text-center py-1">{{rb.plazo_meses}}</td>
														<td class="text-center py-1">{{rb.vencimiento}}</td>
														<td class="text-center py-1">{{rb.garantias}}</td>
														<td class="text-center py-1">{{rb.aforo}}%</td>
														<td class="text-center py-1">{{rb.tasa_de_interes}}%</td>
													</tr>
													<tr>
														<td class="text-center py-1">TOTAL</td>
														<td class="text-center py-1"></td>
														<td class="text-center py-1">${{sumTotal(bancos, 'importe_original_del_financiamiento')}}</td>
														<td class="text-center py-1">${{sumTotal(bancos, 'saldo_actual')}}</td>
														<td class="text-center py-1">${{sumTotal(bancos, 'procion_circulante_saldo_actual')}}</td>
														<td class="text-center py-1">${{sumTotal(bancos, 'largo_plazo_saldo_actual')}}</td>
													</tr>
												</tbody>
											</table>
										</div>
										<div>
											<button class="s-btn s-btn--primary s-btn--outline" @click="showBancosForm">Administrar Pasivos</button>
										</div>
									</div>
								
									<div class="col-12"></div>
									<div class="col-4 p-2">
										<label class="d-block">
											<p class="m-0"><span class="text-danger">*</span>Otras características de la estructura del riesgo dispuesto actual</p>
											<div class="s-tf">
												<label class="s-tf__item">
													<input type="radio" name="sbrg_osii_iii" value="SI" v-model="form.otrasCaracteristicasDeLaEstructuraDelRiesgoDispuestoActual">
													<span class="s-tf__btn">SI</span>
												</label>
												<label class="s-tf__item" >
													<input type="radio" name="sbrg_osii_iii" value="NO" v-model="form.otrasCaracteristicasDeLaEstructuraDelRiesgoDispuestoActual">
													<span class="s-tf__btn">NO</span>
												</label>
											</div>
										</label>
										<div class="py-2" v-if="form.otrosProductosNoDeuda == 'SI'">
											<label class="d-block m-0">
												<p class="m-0"><span class="text-danger">*</span> Comentarios sobre Otros Productos No Deuda</p>
												<textarea class="form-control"   type="text" v-model="form.comentariosOtrosProductosNoDeuda">
												</textarea>
											</label>
										</div>
									</div>
									<div class="col-4 p-2">
										<label class="d-block m-0">
											<p class="m-0"><span class="text-danger">*</span> Número de bancos con los que el cliente trabaja usualmente</p>
											<select class="form-control"   type="text" v-model="form.numeroDeBancosConLosQueElClienteTrabajaUsualmente">
												<option value=""></option>
												<option value="Trabaja con 4 o menos bancos">Trabaja con 4 o menos bancos</option>
												<option value="Trabaja con 5 a 10 banco">Trabaja con 5 a 10 banco</option>
												<option value="Trabaja con más de 10 Bancos">Trabaja con más de 10 Bancos</option>
											</select>
										</label>
									</div>
									<div class="col-4 p-2">
										<label class="d-block m-0">
											<p class="m-0"><span class="text-danger">*</span> Comentario sobre otras características de la exposción del cliente</p>
											<textarea class="form-control"   type="text" v-model="form.comentariosSobreOtrasCaracteristicasDeLaExposicionDelCliente">
											</textarea>
										</label>
									</div>
									<div class="col-12 p-0">
										<!-- <date-picker class="form-control" valueType="format"></date-picker> -->
									</div>
									<div class="col-4 p-2">
										<label class="d-block m-0">
											<p class="m-0"><span class="text-danger">*</span> RAR - Rentabilidad</p>
											<input class="form-control"   type="text" v-model="form.rarRentabilidad">
										</label>
									</div>
									<div class="col-4 p-2">
										<label class="d-block m-0">
											<p class="m-0"> Nivel de WL</p>
											<input class="form-control"   type="text" v-model="form.niveldeWL">
										</label>
									</div>
									<div class="col-4 p-2">
										
									</div>
									<div class="col-4 p-2">
										<label class="d-block m-0">
											<p class="m-0"><span class="text-danger">*</span> Comentarios sobre el RAR</p>
											<textarea class="form-control"   type="text" v-model="form.comentariosSobreRAR"></textarea>
											<characters-available :max="150" :text="form.comentariosSobreRAR"></characters-available>

										</label>
									</div>
									<div class="col-4 p-2">
										<label class="d-block m-0">
											<p class="m-0">Análisis de compensaciones, reciprocidad y transaccionalidad del cliente (rentablidad)</p>
											<textarea class="form-control"   type="text" v-model="form.analisisDeCompensacionesReciprocidadYTransaccionalidadDelCliente">
											</textarea>
										</label>
									</div>
								</div>
							</div>
						</div>
						
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="s-btn s-btn--outline" data-dismiss="modal">Cancelar</button>
					<button type="button" class="s-btn s-btn--primary" data-dismiss="modal" @click="save">Guardar</button>
				</div>
				</div>
			</div>
		</div>
		<!-- ================ -->
		<!-- ================ -->
		<!-- ================ -->
		<div class="modal s-modal fade" id="buroModalGroup" tabindex="-1" role="dialog" aria-labelledby="buroModalGroupLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title w-100 text-center" id="buroModalGroupLabel">Administrar Buró</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
						</button>
					</div>	
					<div class="modal-body">
						<table class="s-table w-100">
							<thead class="small">
								<tr>
									<td class="p-2">Nombre del Cliente</td>
									<td class="p-2">Fecha</td>
									<td class="p-2">Clave de prevención</td>
									<td class="p-2">Numero de bancos</td>
									<td class="p-2">M.N</td>
									<td class="p-2">USD Val.</td>
									<td class="p-2">UDIS Val.</td>
									<td class="p-2">Hasta 30 d.</td>
									<td class="p-2">Hasta 90 d.</td>
									<td class="p-2">Más de 90 d.</td>
									<td class="p-2"></td>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(rb, index) in formBuro">
									<td class="p-1">
										<input class="form-control p-1" style="height: 34px" type="text" v-model="rb.nombre">
									</td>
									<td class="p-1">
										<date-picker v-model="rb.fecha" style="width: 134px"  valueType="format"></date-picker>
									</td>
									<td class="p-1">
										<input class="form-control p-1" style="height: 34px" type="text" v-model="rb.clavePrevencion">
									</td>
									<td class="p-1">
										<input class="form-control p-1" style="height: 34px" type="text" v-model="rb.numeroBancos">
									</td>
									<td class="p-1">
										<input class="form-control p-1" style="height: 34px" type="text" v-model="rb.mn">
									</td>
									<td class="p-1">
										<input class="form-control p-1" style="height: 34px" type="text" v-model="rb.usd">
									</td>
									<td class="p-1">
										<input class="form-control p-1" style="height: 34px" type="text" v-model="rb.udis">
									</td>
									<td class="p-1">
										<input class="form-control p-1" style="height: 34px" type="text" v-model="rb.h30">
									</td>
									<td class="p-1">
										<input class="form-control p-1" style="height: 34px" type="text" v-model="rb.h90">
									</td>
									<td class="p-1">
										<input class="form-control p-1" style="height: 34px" type="text" v-model="rb.m90">
									</td>
									<td>
										<button class="s-btn s-btn--transparent" @click="deleteItem(index, formBuro)">
											<SvgIcon name="delete"/>
										</button>
									</td>
								</tr>
							</tbody>
						</table>	
						<div class="text-right py-1">
							<button class="s-query-btn" @click="addNewBuro">
								<svg-icon name="add" />
							</button>
						</div>
						<div class="bg-light p-2">
							<div class="text-right">
								<button class="s-btn s-btn--primary" @click="() => {showModal('#buroSaveModalGroup')}">Save</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="modal s-modal fade" id="buroSaveModalGroup" tabindex="-1" role="dialog" aria-labelledby="buroSaveModalGroupLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title w-100 text-center" id="buroSaveModalGroupLabel">Select whether you want to save the changes</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
						</button>
					</div>	
					<div class="modal-body">
						<div class=" p-2">
							<div class="text-center">
								<button class="s-btn s-btn--primary  mx-1" @click="cancelBuro">Cancel</button>
								<button class="s-btn s-btn--primary s-btn--outline mx-1" @click="saveBuro">Save</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal s-modal fade" id="bancosSaveModalGroup" tabindex="-1" role="dialog" aria-labelledby="bancosSaveModalGroupLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-sm" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title w-100 text-center" id="bancosSaveModalGroupLabel">Select whether you want to save the changes</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
						</button>
					</div>	
					<div class="modal-body">
						<div class=" p-2">
							<div class="text-center">
								<button class="s-btn s-btn--primary  mx-1" @click="cancelBancos">Cancel</button>
								<button class="s-btn s-btn--primary s-btn--outline mx-1" @click="saveBancos">Save</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal s-modal fade" id="bancosModalGroup" tabindex="-1" role="dialog" aria-labelledby="bancosModalGroupLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title w-100 text-center" id="bancosModalGroupLabel">Administrar Pasivos</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
						</button>
					</div>	
					<div class="modal-body">
						<table class="s-table w-100">
							<thead class="small">
								<tr>
									<td class="p-2">Nombre institución bancaria</td>
									<td class="p-2">Tipo de crédito</td>
									<td class="p-2">Importe original del financiamiento</td>
									<td class="p-2">Saldo actual</td>
									<td class="p-2">Porción circulante saldo actual</td>
									<td class="p-2">Largo plazo saldo actual</td>
									<td class="p-2">Destino</td>
									<td class="p-2">Plazo meses</td>
									<td class="p-2">Vencimiento</td>
									<td class="p-2">Garantías</td>
									<td class="p-2">Aforo</td>
									<td class="p-2">Tasa de interés</td>
									<td></td>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(rb, index) in formBancos">
									<td class="p-1">
										<input class="form-control p-1" style="height: 34px" type="text" v-model="rb.nombre">
									</td>
									<td class="p-1">
										<input class="form-control p-1" type="text" style="height: 34px"  v-model="rb.tipo_credito">
									</td>
									<td class="p-1">
										<input class="form-control p-1" type="text" style="height: 34px"  v-model="rb.importe_original_del_financiamiento">
									</td>
									<td class="p-1">
										<input class="form-control p-1" type="text" style="height: 34px"  v-model="rb.saldo_actual">
									</td>
									<td class="p-1">
										<input class="form-control p-1" type="text" style="height: 34px"  v-model="rb.procion_circulante_saldo_actual">
									</td>
									<td class="p-1">
										<input class="form-control p-1" type="text" style="height: 34px"  v-model="rb.largo_plazo_saldo_actual">
									</td>
									<td class="p-1">
										<input class="form-control p-1" type="text" style="height: 34px"  v-model="rb.destino">
									</td>
									<td class="p-1">
										<input class="form-control p-1" type="text" style="height: 34px"  v-model="rb.plazo_meses">
									</td>
									<td class="p-1">
										<input class="form-control p-1" type="text" style="height: 34px"  v-model="rb.vencimiento">
									</td>
									<td class="p-1">
										<input class="form-control p-1" type="text" style="height: 34px"  v-model="rb.garantias">
									</td>
									<td class="p-1">
										<input class="form-control p-1" type="text" style="height: 34px"  v-model="rb.aforo">
									</td>
									<td class="p-1">
										<input class="form-control p-1" type="text" style="height: 34px"  v-model="rb.tasa_de_interes">
									</td>
									<td>
										<button class="s-btn s-btn--transparent" @click="deleteItem(index, formBancos)">
											<SvgIcon name="delete"/>
										</button>
									</td>
								</tr>
							</tbody>
						</table>	
						<div class="text-right py-1">
							<button class="s-query-btn" @click="addNewBanco">
								<svg-icon name="add" />
							</button>
						</div>
						<div class="bg-light p-2">
							<div class="text-right">
								<button class="s-btn s-btn--primary" @click="showModal('#bancosSaveModalGroup')">Save</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="modal s-modal fade" id="sumaryPositionRiskGroup" tabindex="-1" role="dialog" aria-labelledby="sumaryPositionRiskGroupLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title w-100 text-center" id="sumaryPositionRiskGroupLabel">Insertar registro</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
						</button>
					</div>	
					<div class="modal-body">
						<div class="py-4 d-flex flex-wrap">
							<div class="col-12 p-2">
								<label class="d-block m-0">
									<p class="m-0"><span class="text-danger">*</span> Selecciona un producto</p>
									<select class="form-control w-100" name="gupo" v-model="formPosicionRiesgo.producto">
										<option value="Arrendamiento Puro">Arrendamiento Puro</option>
										<option value="Cartas de Crédito">Cartas de Crédito</option>
										<option value="Crediproveedores">Crediproveedores</option>
										<option value="Crédito de habitación o Avío">Crédito de habitación o Avío</option>
										<option value="Crédito Prendario">Crédito Prendario</option>
										<option value="Crédito refaccionario">Crédito refaccionario</option>
										<option value="Crédito Simple">Crédito Simple</option>
										<option value="Plan Piso">Plan Piso</option>
									</select>
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0">Límite actual</p>
									<input class="form-control w-100" type="number" name="gupo" v-model="formPosicionRiesgo.limiteActual">
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0">Comprometido</p>
									<input class="form-control w-100" type="number" name="gupo" v-model="formPosicionRiesgo.comprometido">
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0">No comprometido</p>
									<input class="form-control w-100" type="number" name="gupo" v-model="formPosicionRiesgo.noComprometido">
								</label>
							</div>
							<div class="col-6 p-2">
								<label class="d-block m-0">
									<p class="m-0">Dispuesto</p>
									<input class="form-control w-100" type="number" name="gupo" v-model="formPosicionRiesgo.dispuesto">
								</label>
							</div>
							<div class="col-12 p-2">
								<label class="d-block m-0">
									<p class="m-0">Comentarios/Garantías</p>
									<textarea class="form-control w-100" type="number" name="gupo" v-model="formPosicionRiesgo.comentariosGarantias"></textarea>
								</label>
							</div>
						</div>
						<div class="py-4 text-center">
							<button class="s-btn s-btn--primary s-btn--outline mx-1" data-dismiss="modal">
								Cancelar
							</button>
							<button class="s-btn s-btn--primary mx-1" @click="savePosition">
								Guardar
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>
